<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <b-row>
      <b-col b-col md="4" class="my-1">
        Total {{totalRows}}
      </b-col>
      <b-col b-col md="4" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="table-transition-example"
          pills
          align="fill"
          class="my-1"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-card bg-variant="light">
      <b-card-group deck>
        <div v-for="(item) in items" :key="item.id" class="col-xs-6 col-sm-6 col-md-6">
          <!-- <collection-image-card
            :data="item"
            :included="included"
          ></collection-image-card> -->
          <item-image-association-card
            :data_item="item"
            :mappings="item_associations(item.relationships['item-image-associations'].data)"
            :included="included"
          >
          </item-image-association-card>
        </div>
      </b-card-group>
    </b-card>
    <b-row>
      <b-col b-col md="4" class="my-1">
        Total {{totalRows}}
      </b-col>
      <b-col b-col md="4" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="table-transition-example"
          pills
          align="fill"
          class="my-1"
          size="sm"
        ></b-pagination>
      </b-col>
      <b-col md="4" class="my-1">
        <b-button variant="outline-primary" size="sm" >Add Entry</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {http_v1} from '@/axios'
import VuexBreadcrumb from './VuexBreadcrumb.vue'
// import CollectionImageCard from './CollectionImageCard.vue'
import ItemImageAssociationCard from './ItemImageAssociationCard.vue'

export default {
  components: {
    VuexBreadcrumb,
    // CollectionImageCard,
    ItemImageAssociationCard
  },
  // props: {
  //   id: {
  //     Type: String
  //   }  
  // },
  data () {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      items: [],
      included: [],
      value: 3
    }
  },
  methods: {
    item_associations(data_array) {
      let ret_array = []
      data_array.forEach(element => {
        let item = this.included.find( el =>
          el.type === 'item-image-associations'
          && el.id === element.id)
        ret_array.push(item)
      })
      return ret_array
    },
    callApi () {
      http_v1.get (
        'api/public/v1/collection_images_with_product_items', {
          params: {
            // collection_ids: this.id,
            page: this.currentPage
          }
        }
      ).then(response => {
        if(response.status == 200) {
          this.totalRows = response.headers.total
          this.perPage = response.headers['per-page']
          this.items = response.data.data
          this.included = response.data.included
        }
        else {
          window.console.log(response.status)
        }
      }).catch(error => {
        this.items = []
        this.totalRows = 0
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          window.console.log(message)
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
  },
  watch: {
    currentPage() {
      this.callApi()
    }
  },
  mounted() {
    this.callApi()
  }
}
</script>
